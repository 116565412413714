<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcode";
import { PasswordInput } from "vant";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      qrtoken: "",
      step: 0,
      loginstatus: {
        phone: "",
        password: ""
      }
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.payment.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.payment.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
    this.getqrtoken();
  },
  methods: {
    getqrtoken(){
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getqrtoken",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.qrtoken = response.data.qrtoken;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    save(){
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "saveqrtoken",
            qrtoken: that.qrtoken,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.$message({
            message: response.data.message,
            type: "success",
          });
          that.getqrtoken();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <canvas style="display: none" ref="canvas"></canvas>
            <p>
              本服务由AICOPAY提供，关于企业及个人信息请参照<a href="https://aicopay.com/privacy.shtml" target="_blank">《AICOPAY
                用户隐私政策》</a>与<a href="https://aicopay.com/terms.shtml" target="_blank">《AICOPAY用户服务协议》</a>
            </p>
            <p>
              获取支付服务授权需要您通过https://admin.aicoiot.beer/或https://aicopay.com申请或配置第三方支付(微信、支付宝等)之后授权本后台使用您配置的支付渠道及个人资料。
            </p>
            <p>本功能不会泄露您的个人信息与隐私信息。</p>
            <el-form ref="form" label-width="80px">
              <el-form-item label="QrToken">
                <el-input v-model="qrtoken"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="save">{{ $t("device.hd.save") }}</el-button>
              </el-form-item>
            </el-form>
            <!-- <el-button type="primary" @click="getpaymentauth">{{
              $t("menuitems.clouds.payment.auth")
            }}</el-button> -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>